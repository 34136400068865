import "./css/eduzz.css";
import React from "react";

import AccordionDescription from "../../../../components/AccordionDescription";
// import GridContainer from "../../../../components/GridContainer";
import Translate from "../../../../components/Translate";
// import PricingCard from "../../../../components/PricingCard";
// import CheckBoxList from "../../../../CheckBoxList";
// import CheckBoxListItem from "../../../../CheckBoxListItem";
// import colors from "../../../../themes/colors";

export default function EduzzPage() {
  return (
    <div>
      {/* <!-- Header --> */}

      <header className="header">
        {/* <!-- imagens --> */}

        <section id="inicio">
          <div className="container">
            <img src="/assets/profile/eduzz/logos.png" alt=" " />
          </div>
        </section>

        {/* <!-- Texto --> */}

        <p>
          Aumente suas vendas com a parceria Eduzz e BizPik: Soluções de Recuperação de Vendas e
          Carrinho Abandonado
        </p>
        <h4>
          Escolha o plano ideal para o seu negócio e aproveite benefícios exclusivos para os
          produtores da Eduzz.
        </h4>
      </header>

      {/* <!-- Botao --> */}

      <div id="section1" className="fixed-container">
        <img src="/assets/profile/eduzz/quadrado.png" alt=" " />
        <a
          href="https://www.bizpik.com.br?utm_source=store_eduzz&utm_medium=affiliate&utm_campaign=parceria_eduzz"
          className="button-primary"
          rel="noreferrer"
          target="blank"
        >
          Quero Recuperar Minhas Vendas Agora
        </a>
      </div>
      <br />
      <br />

      {/* <!-- Benefícios --> */}
      <section id="beneficios">
        <section className="benefits">
          <div className="benefits-content">
            <img src="/assets/profile/eduzz/fundo1.png" alt="Benefícios Exclusivos" />
            <div className="benefits-text">
              <h2>Benefícios Exclusivos para Clientes Eduzz!</h2>
              <p>
                Nossos links de pagamento, integrados a uma das plataformas mais renomadas do
                mercado, oferecem planos com descontos de até 50%
              </p>
              {/* <br /> */}
              <h3>Aproveite e impulsione suas vendas!</h3>
            </div>
          </div>
        </section>
      </section>
      <br />

      {/* <!-- Subtitulo --> */}
      <div>
        <section className="subtitle">
          <p className="custom-border">Escolha o ideal para o seu negócio</p>
        </section>
      </div>

      {/* <!-- Planos --> */}
      {/* <!-- Plano Basico --> */}

      <section className="plans" id="planos">
        <div className="plan plan-basic">
          <h3>Plano Basic</h3>
          <p className="prod">Para Produtores Iniciais</p>
          <hr />

          <div className="wrapper">
            <div className="vertical-line" />
            <div className="text-group">
              <p className="priceold">
                <s>De R$399</s>
              </p>
              <p className="price1">Por R$199 mês</p>
              <p className="pricedesconto">+20% da fatura recuperada</p>
            </div>
          </div>

          <div className="container-grid">
            <div className="item">
              <img src="/assets/profile/eduzz/check.png" className="image" alt=" " />
              <p className="texto">Copy Automática (não editável);</p>
            </div>
            <div className="item">
              <img src="/assets/profile/eduzz/check.png" className="image" alt=" " />
              <p className="texto">Fluxo de interação automática com o comprador;</p>
            </div>
            <div className="item">
              <img src="/assets/profile/eduzz/check.png" className="image" alt=" " />
              <p className="texto">Fluxo fixo (sem personalização)</p>
            </div>
            <div className="item">
              <img src="/assets/profile/eduzz/check.png" className="image" alt=" " />
              <p className="texto">Link de pagamento integrado da Eduzz</p>
            </div>
            <div className="item">
              <img src="/assets/profile/eduzz/check.png" className="image" alt=" " />
              <p className="texto">
                Leads e histórico de jornada dos leads com timeline de interações
              </p>
            </div>
          </div>
          <hr />
          <p className="titulo">Este Plano ainda oferece:</p>
          <br />

          <div className="texto2">
            <h3>Visualize e Analise o Desempenho:</h3>
            <ul>
              <li>Dashboard com volumetria, número de leads e taxa de conversão;</li>
              <li>Análise detalhada para entender o desempenho das ações;</li>
              <li>Extrato de cobranças.</li>
            </ul>

            <h3>Canais de Comunicação:</h3>
            <ul>
              <li>WhatsApp Business (API oficial).</li>
            </ul>
          </div>

          <a
            href="https://www.bizpik.com.br?utm_source=store_eduzz&utm_medium=affiliate&utm_campaign=parceria_eduzz"
            className="button-plan1"
            target="blanck"
          >
            Contratar Agora
          </a>
        </div>

        {/* <!-- Plano Pro --> */}

        <div className="plan plan-pro">
          <h3>Plano Pro</h3>
          <p className="prod2">Para quem busca resultados avançados</p>
          <hr />

          <div className="wrapper">
            <div className="vertical-line2" />
            <div className="text-group">
              <p className="priceold">
                <s>De R$799</s>
              </p>
              <p className="price2">Por R$499 mês</p>
              <p className="pricedesconto">+20% da fatura recuperada</p>
            </div>
          </div>

          <div className="container-grid">
            <div className="item">
              <img src="/assets/profile/eduzz/check1.png" className="image" alt=" " />
              <p className="texto">Copy Automática com opção de personalização;</p>
            </div>
            <div className="item">
              <img src="/assets/profile/eduzz/check1.png" className="image" alt=" " />
              <p className="texto">
                Fluxo personalizado e multicanal, adaptável ao perfil do cliente;
              </p>
            </div>
            <div className="item">
              <img src="/assets/profile/eduzz/check1.png" className="image" alt=" " />
              <p className="texto">Transbordo para central de atendimento.</p>
            </div>
            <div className="item">
              <img src="/assets/profile/eduzz/check1.png" className="image" alt=" " />
              <p className="texto">Link de pagamento integrado da Eduzz;</p>
            </div>
            <div className="item">
              <img src="/assets/profile/eduzz/check1.png" className="image" alt=" " />
              <p className="texto">
                Leads e histórico de jornada dos leads com timeline de interações.
              </p>
            </div>
          </div>
          <hr />
          <p className="titulo">Este Plano ainda oferece:</p>
          <br />

          <div className="texto3">
            <h3>Gestão de Atendimento Avançada:</h3>
            <ul>
              <li>Controle de atendentes e departamentos;</li>
              <li>Visão supervisionada e gestão do fluxo de trabalho;</li>
              <li>Segmentação de clientes e agendamento de disparos.</li>
            </ul>

            <h3>Visualize e Analise o Desempenho:</h3>
            <ul>
              <li>Dashboard com volumetria, leads, taxa de conversão e análise detalhada;</li>
              <li>Extrato de cobranças e análise avançada de dados.</li>
            </ul>

            <h3>Canais de Comunicação:</h3>
            <ul>
              <li>WhatsApp Business (API oficial), Facebook, Telegram, Instagram;</li>
              <li>
                Central de Atendimento Completa: com gestão de equipe sem custo adicional.
              </li>
            </ul>
          </div>

          <a
            href="https://www.bizpik.com.br?utm_source=store_eduzz&utm_medium=affiliate&utm_campaign=parceria_eduzz"
            className="button-plan2"
            target="blanck"
          >
            Contratar Agora
          </a>
        </div>
      </section>

      {/* <!-- Subtitulo --> */}

      <section className="subtitle">
        <p>Depoimentos</p>
      </section>

      {/* <!-- Depoimentos --> */}

      <section id="depoimentos" />
      <div className="carousel">
        <div className="carousel-container">
          <div className="testimonial-card">
            <img
              src="/assets/profile/eduzz/avatar1.png"
              alt="Foto de Rafael Martinez"
              className="testimonial-image"
            />
            <div className="testimonial-content">
              <h3>Rafael Martinez</h3>
              <p>
                Com a Bizpik, recuperamos muitos carrinhos abandonados. O sistema é prático e
                eficiente para aumentar nossas conversões!
              </p>
              <div className="stars">★★★★★</div>
            </div>
          </div>

          <div className="testimonial-card">
            <img
              src="/assets/profile/eduzz/avatar2.png"
              alt="Foto de Gabriela M."
              className="testimonial-image"
            />
            <div className="testimonial-content">
              <h3>Gabriela M.</h3>
              <p>
                A automação da Bizpik nos ajuda a manter contato com clientes e recuperar vendas
                de forma rápida e ágil.
              </p>
              <div className="stars">★★★★★</div>
            </div>
          </div>

          <div className="testimonial-card">
            <img
              src="/assets/profile/eduzz/avatar3.png"
              alt="Foto de Pedro Albuquerque"
              className="testimonial-image"
            />
            <div className="testimonial-content">
              <h3>Pedro Albuquerque</h3>
              <p>
                Bizpik mudou o jogo! A recuperação de vendas agora é rápida e faz uma diferença
                significativa nas vendas.
              </p>
              <div className="stars">★★★★★</div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Benefícios 2 --> */}

      {/* <!-- Benefícios --> */}
      <section id="benefits2">
        <section className="benefits2">
          <div className="benefits-content2">
            <img src="/assets/profile/eduzz/fundo2.png" alt=" " />
            <div className="benefits-text2">
              <h2>Está pronto para impulsionar suas vendas e fidelizar seus clientes?</h2>
              <a
                href="https://www.bizpik.com.br?utm_source=store_eduzz&utm_medium=affiliate&utm_campaign=parceria_eduzz"
                className="button-primary2"
              >
                Quero Recuperar Minhas Vendas Agora
              </a>
            </div>
          </div>
        </section>
      </section>
      <br />

      {/* <!-- Subtitulo --> */}

      <section className="subtitle" id="faq">
        <p>FAQ e Perguntas Frequentes</p>
      </section>
      <br />
      <br />
      {/* <!-- TESTE --> */}
      {/* <div className="plans">
        <PricingCard
          title="Prano Basic"
          text="Para Produtores iniciais"
          monthlyPrice={199}
          features={[
            "100 leads",
            "100 contatos por minuto",
            "Transbordo para central de atendimento",
            "Link de pagamento integrado",
            "Lead e histórico de jornada dos leads",
            "Copy Automática com opção de personalização"
          ]}
          lightColorTheme="#801AFF"
        />
      </div> */}
      {/* <!-- FAQ --> */}

      <div className="cssaccordion">
        <section className="accordion">
          <AccordionDescription
            id="text_acordion"
            title="Quais canais de mensagens são suportados pelo Bizpik?"
            text="O BizPik suporta integrações com os principais canais de mensagem, incluindo WhatsApp*, Instagram, Facebook Messenger, Telegram, entre outros."
          />
          <AccordionDescription
            id="text_acordion"
            title="Qual é a diferença entre os planos Basic e Pro?"
            text="O plano Basic oferece recursos básicos, enquanto o plano Pro inclui funcionalidades avançadas para impulsionar suas vendas."
          />
          <AccordionDescription
            id="text_acordion"
            title="Como funciona a cobrança pela recuperação de vendas?"
            text="A cobrança é feita com base no valor das vendas recuperadas pela nossa plataforma, oferecendo transparência nos custos."
          />
          <Translate />
          {/* <colors /> */}
        </section>
      </div>
    </div>
  );
}
