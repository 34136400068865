import React from "react";
import { Box, Chip, Grid, Hidden, Paper, Stack, styled, Typography } from "@mui/material";

import AccordionDescription from "../../../../components/AccordionDescription";
import GridContainer from "../../../../components/GridContainer";
import Translate from "../../../../components/Translate";
import colors from "../../../../themes/colors";
import { IPageConfigOrigin, IPageConfigSolution } from "../../../../types/page";
import { IPageConfigPartnerSolutionsPlugin } from "../../../../types/partner/basic";

// import minhaImagem from ""

const AccordionContainerSolutionSection = styled(Paper)(({ theme }) => ({
  borderRadius: 0,
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  marginTop: "5rem",
  boxShadow: theme.shadows[3],
  marginLeft: theme.spacing(-4),
  marginRight: theme.spacing(-4),

  [theme.breakpoints.up("sm")]: {
    marginLeft: 0,
    marginRight: 0
  },

  [theme.breakpoints.up("lg")]: {
    maxWidth: "36rem",
    marginLeft: "auto",
    marginTop: 0,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6)
  },

  [`
    & .MuiAccordionSummary-content:not(.Mui-expanded),
    & .MuiAccordionSummary-content.Mui-expanded
  `]: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  }
}));

interface SolutionsSectionProps {
  origins: IPageConfigOrigin[];
  aiChat: IPageConfigSolution;
  aiCommerce: IPageConfigSolution;
  aiCRM: IPageConfigSolution;
  plugin: IPageConfigPartnerSolutionsPlugin;
}

export default function SolutionsSection({
  origins,
  aiChat,
  aiCommerce,
  aiCRM,
  plugin
}: SolutionsSectionProps) {
  const [expandedItem, setExpandedItem] = React.useState<number>(1);

  const handleAccordionExpandClick = (item: number) => {
    if (expandedItem === item) {
      setExpandedItem(0);
    } else {
      setExpandedItem(item);
    }
  };

  return (
    <Box component="section">
      <GridContainer childrenProps={{ muiGrid: { sx: { paddingTop: 32 } } }}>
        <div className="absolute top-40 md:top-16 h-0 w-0" id="solutions" />

        <Grid container justifyContent="center">
          <Grid container item xs={12} columnSpacing={6} justifyContent="center">
            <Grid item xs={12} sm={10} lg={4}>
              <Box className="max-w-[36rem]">
                <Typography
                  variant="button"
                  color={theme => theme.palette.text.secondary}
                  children={<Translate i18nKey="section:solutions:subtitle" />}
                />

                <Typography
                  color={colors.dodgerBlue}
                  sx={{ fontWeight: 700, fontSize: 40 }}
                  children={<Translate i18nKey="section:solutions:title" />}
                  className="mb-8"
                />

                <Typography className="mb-2 font-normal" fontSize={16} variant="inherit">
                  <Translate i18nKey="section:solutions:breathing" />
                </Typography>

                <Typography
                  className="font-bold border-l-4 border-solid pl-6 my-8"
                  sx={{ borderColor: colors.guppieGreen, lineHeight: 1.25 }}
                  fontSize={16}
                  variant="inherit"
                >
                  <Translate i18nKey="section:solutions:footnote" />
                </Typography>

                <Box className="mt-16">
                  <Typography
                    variant="button"
                    color={theme => theme.palette.text.secondary}
                    children={<Translate i18nKey="section:solutions:plug_play:subtitle" />}
                  />

                  <Typography component="h2" sx={{ fontWeight: 700, fontSize: 46 }}>
                    <Translate
                      i18nKey="section:solutions:plug_play:title"
                      components={{
                        "1": (
                          <Typography
                            component="span"
                            variant="inherit"
                            sx={{ color: colors.violet }}
                          />
                        ),

                        "2": (
                          <Typography
                            component="span"
                            variant="inherit"
                            sx={{ color: colors.deepPink }}
                          />
                        )
                      }}
                    />
                  </Typography>

                  <Typography
                    className="font-bold border-l-4 border-solid pl-6 my-6"
                    sx={{ borderColor: colors.guppieGreen, lineHeight: 1.25 }}
                    fontSize={16}
                    variant="inherit"
                  >
                    <Translate i18nKey="section:solutions:plug_play:footnote" />
                  </Typography>

                  <Hidden mdDown>
                    <Stack component="ul" className="ml-6" direction="row" spacing={2}>
                      {origins.map(origin =>
                        origin.name === "Reclame aqui" ||
                        origin.name === "Twitter" ||
                        origin.name === "Linkedin" ? (
                          <img
                            key={origin.name}
                            src={origin.image}
                            alt={origin.name}
                            className="grayscale"
                          />
                        ) : (
                          <img key={origin.name} src={origin.image} alt={origin.name} />
                        )
                      )}
                    </Stack>
                  </Hidden>
                  <Hidden smUp>
                    <Stack component="ul" className="" direction="row" spacing={1}>
                      {origins.map(origin =>
                        origin.name === "Reclame aqui" ||
                        origin.name === "Twitter" ||
                        origin.name === "Linkedin" ? (
                          <img
                            key={origin.name}
                            src={origin.image}
                            alt={origin.name}
                            className="grayscale"
                          />
                        ) : (
                          <img key={origin.name} src={origin.image} alt={origin.name} />
                        )
                      )}
                    </Stack>
                  </Hidden>
                  <Hidden mdDown>
                    <Box className="flex items-end w-[88%] pt-2 justify-end">
                      <Chip
                        variant="filled"
                        className="rounded-lg sm:my-0 sm:ml-6 lg:ml-0 w-32 self-end"
                        sx={{ backgroundColor: colors.yellowSunny }}
                        label={
                          <Typography
                            children="Em Breve"
                            fontSize={12}
                            className="font-medium uppercase"
                          />
                        }
                      />
                    </Box>
                  </Hidden>
                  <Hidden smUp>
                    <Box className="flex items-end justify-end pt-2">
                      <Chip
                        variant="filled"
                        className="rounded-lg sm:my-0 sm:ml-6 lg:ml-0 w-32 self-end"
                        sx={{ backgroundColor: colors.yellowSunny }}
                        label={
                          <Typography
                            children="Em Breve"
                            fontSize={12}
                            className="font-medium uppercase"
                          />
                        }
                      />
                    </Box>
                  </Hidden>
                </Box>

                <Box className="mt-16">
                  <Typography
                    variant="button"
                    color={theme => theme.palette.text.secondary}
                    children={<Translate i18nKey="section:partner:label:plugin" />}
                  />

                  <Typography
                    color={colors.violet}
                    component="h2"
                    sx={{ fontWeight: 700, fontSize: 46 }}
                  >
                    <Translate customKey={plugin.title} />
                  </Typography>

                  <Typography
                    className="font-bold border-l-4 border-solid pl-6 my-6"
                    sx={{ borderColor: colors.guppieGreen, lineHeight: 1.25 }}
                    fontSize={16}
                    variant="inherit"
                  >
                    <Translate customKey={plugin.description} />
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={10} lg={6}>
              <Stack direction="column" justifyContent="space-between" height="100%">
                <AccordionContainerSolutionSection>
                  <AccordionDescription
                    id="omnichannel-accordion-1"
                    tag={aiChat.soon ? "soon" : "new"}
                    title={<Translate i18nKey="section:solutions:accordion_1:title" />}
                    text={<Translate customKey={aiChat.text} />}
                    expanded={expandedItem === 1}
                    size="large"
                    onClick={() => handleAccordionExpandClick(1)}
                  />

                  <AccordionDescription
                    id="omnichannel-accordion-2"
                    tag={aiCRM.soon ? "soon" : "new"}
                    title={<Translate i18nKey="section:solutions:accordion_2:title" />}
                    text={<Translate customKey={aiCRM.text} />}
                    expanded={expandedItem === 2}
                    size="large"
                    onClick={() => handleAccordionExpandClick(2)}
                  />

                  <AccordionDescription
                    id="omnichannel-accordion-3"
                    tag={aiCommerce.soon ? "soon" : "new"}
                    title={<Translate i18nKey="section:solutions:accordion_3:title" />}
                    text={<Translate customKey={aiCommerce.text} />}
                    expanded={expandedItem === 3}
                    size="large"
                    onClick={() => handleAccordionExpandClick(3)}
                  />
                </AccordionContainerSolutionSection>

                <Hidden mdDown>
                  {/* <Box className="lg:max-w-xl lg:ml-auto -mb-2">
                    <Box
                      component="img"
                      src={plugin.solutionImage}
                      alt="Vesti Plugin"
                      sx={{ width: "200%" }}
                    />
                  </Box> */}
                  <Box className="lg:max-w-xl lg:ml-auto -mb-2">
                    <Box
                      component="img"
                      src="https://bizpik-website.s3.us-east-1.amazonaws.com/assets/partners/inchurch/plugin_inchurch.svg" // Adicione o URL ou caminho da imagem
                      alt="Inchurch plugin"
                      sx={{ width: "200%" }} // Você pode ajustar o tamanho aqui
                    />
                  </Box>
                </Hidden>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </GridContainer>
    </Box>
  );
}
