import { Box, CircularProgress, Grow } from "@mui/material";
import React from "react";

interface LoaderScreenProps {
  logoUrl?: string;
  logoAlt?: string;
}

export default function LoaderScreen({ logoUrl, logoAlt = "page logo" }: LoaderScreenProps) {
  // console.log("logoUrl", logoUrl);
  return (
    <Box bgcolor="background.default" className="fixed top-0 left-0 right-0 bottom-0 z-[99999]">
      <Box className="w-full h-full flex flex-col justify-center items-center">
        {logoUrl && (
          <div className="mb-12">
            <Grow in appear style={{ transformOrigin: "80px 0 0" }} timeout={1000}>
              <img src={logoUrl} alt={logoAlt} width="240" className="w-32 lg:w-60" />
            </Grow>
          </div>
        )}

        <CircularProgress color="secondary" thickness={4} size={60} />
      </Box>
    </Box>
  );
}
