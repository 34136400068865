const socialMedias = [
  {
    name: "WhatsApp",
    address: "https://wa.me/5511978791500",
    image: "https://bizpik-website.s3.amazonaws.com/assets/social/mono/whatsapp.svg"
  },
  {
    name: "Linkedin",
    address: "https://www.linkedin.com/company/bizpik",
    image: "https://bizpik-website.s3.amazonaws.com/assets/social/mono/linkedin.svg"
  },
  {
    name: "Instagram",
    address: "https://www.instagram.com/bizpik.ai/",
    image: "https://bizpik-website.s3.amazonaws.com/assets/social/mono/instagram.svg"
  },
  {
    name: "Facebook",
    address: "https://facebook.com/bizpik",
    image: "https://bizpik-website.s3.amazonaws.com/assets/social/mono/facebook.svg"
  },
  {
    name: "Youtube",
    address: "https://www.youtube.com/channel/UCvQyx6dLUhgBu9D0EZdM8Ug",
    image: "https://bizpik-website.s3.amazonaws.com/assets/social/mono/youtube.svg"
  }
];

export default socialMedias;
