/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { LocationDescriptor } from "history";
import { useHistory } from "react-router-dom";

import LoaderScreen from "../../LoaderScreen";

import { fetchWebSiteContent, webSiteInfo } from "../../../payload/data";

import { addPageConfigI18nTranslations, overridePageConfig } from "../../../payload/partner";
import { IPageConfigResponseModel } from "../../../types/api";
import NavigationTemplate from "../../../templates/NavigationTemplate";
import { createNavigationLinks } from "../../../payload/navigation";

import EduzzPage from "./components/Page";

export default function EduzzWebsite() {
  const [content, setContent] = React.useState<IPageConfigResponseModel | null>(null);
  const history = useHistory<
    | {
        config?: IPageConfigResponseModel | undefined;
        message?: string | undefined;
      }
    | undefined
  >();

  const fetchEduzzWebSiteContent = () => {
    fetchWebSiteContent(webSiteInfo.eduzz.id)
      .then(data => {
        setContent(overridePageConfig(data, webSiteInfo.eduzz.id));
        addPageConfigI18nTranslations(data.translations);
      })
      .catch(error => {
        let to: LocationDescriptor<{ message: string }> | null = null;

        if (typeof error === "string")
          to = {
            pathname: "/error",
            state: {
              message: error
            }
          };
        else
          to = {
            pathname: "/error",
            state: {
              message: "Falha na conexão"
            }
          };

        history.replace(to);
      });
  };

  React.useEffect(() => {
    const { state } = history.location;

    if (state && state.config) {
      setContent(state.config);
      addPageConfigI18nTranslations(state.config.translations);
    } else {
      fetchEduzzWebSiteContent();
    }
  }, []);

  if (!content) {
    return <LoaderScreen logoUrl={webSiteInfo.eduzz.loadingImage} />;
  }

  const partnerConfig = content.partner!.eduzz!;

  return (
    <NavigationTemplate
      links={createNavigationLinks(webSiteInfo.eduzz.path, {
        clientsLinkVisible: content.client.companies.length > 0,
        producerLinkVisible: partnerConfig.section.producers.people.length > 0
      })}
    >
      <EduzzPage />
    </NavigationTemplate>
  );
}
