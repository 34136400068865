import React from "react";
import { Typography } from "@mui/material";

import Slider from "react-slick";
import ChannelSliderArrow from "./ChannelSliderArrow";
import { IPageConfigOrigin } from "../types/page";

interface ChannelSliderProps {
  origins: IPageConfigOrigin[];
  textColor?: string;
  textSocial?: string;
}

export default function ChannelSlider({ origins, textColor, textSocial }: ChannelSliderProps) {
  return (
    <Slider
      nextArrow={<ChannelSliderArrow />}
      prevArrow={<ChannelSliderArrow />}
      infinite
      vertical
      autoplay
      autoplaySpeed={1600}
    >
      {origins.map(origin => (
        <Typography
          key={origin.name}
          component="p"
          children={origin.name}
          sx={{ lineHeight: "1.5 !important", color: textColor ?? textSocial }}
          className="font-extrabold text-5xl"
        />
      ))}
    </Slider>
  );
}
