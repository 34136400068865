/* eslint-disable prettier/prettier */
import { Typography } from "@mui/material";

function ServiceLevelAgreement() {
  return (
    <Typography>
             <div>
             <br />
                <strong> ACORDO DE NÍVEL DE SERVIÇO (SLA) </strong>
             <p>
             <br />

             <strong>Introdução</strong>
             <br />
             No ambiente dinâmico e competitivo de hoje, a tecnologia da informação (TI) 
             desempenha um papel crucial no sucesso das organizações. Para garantir que 
             os serviços de TI atendam às expectativas e necessidades dos negócios, é 
             fundamental estabelecer acordos claros e mensuráveis entre os provedores 
             de serviços e seus clientes. Este documento, o Acordo de Nível de Serviço (SLA), 
             define os termos e condições sob os quais os serviços de TI serão fornecidos, 
             incluindo os níveis de desempenho, disponibilidade e suporte técnico.
             </p>
            </div>
            <br />

            <div>
                <p>
                    <strong>Objetivo do SLA</strong>
                    <br />
                    O objetivo deste SLA é assegurar que todos os serviços de TI sejam entregues de 
                    maneira eficiente e eficaz, alinhando-se às metas estratégicas da organização e 
                    proporcionando um alto nível de satisfação ao cliente.
                </p>
                <br />
            </div>

            <div>
                <p>
                    <strong>Termos importantes que você precisa conhecer</strong>
                    <br / >
                    Para garantir que tudo fique claro e simples, aqui estão algumas terminologias que 
                    usamos ao longo deste SLA. Elas são essenciais para entender como cuidamos da sua 
                    experiência e o que cada situação envolve:
                </p>
                <br />
            </div>

            <div>
                <p>
                    <strong>1. Requisitos</strong>
                    <br / >
                    São as especificações técnicas e operacionais necessárias para garantir que a solução 
                    Bizpik funcione adequadamente para você. Isso envolve aspectos como configurações da 
                    plataforma, acessos e outros detalhes que definem o serviço que será oferecido.
                </p>
                <br />
            </div>



            <div>
                <p>
                    <strong>2. Incidentes</strong>
                    <br / >
                    Qualquer evento inesperado que interrompa ou prejudique o desempenho do serviço que você 
                    contratou. Pode ser uma queda no sistema, um erro temporário ou qualquer instabilidade 
                    que impacte sua operação.
                </p>
                <br />
            </div>



            <div>
                <p>
                    <strong>3. Problema</strong>
                    <br / >
                    A causa raiz de um ou mais incidentes. Se houver algo que esteja gerando múltiplos incidentes, 
                    investigamos até encontrar o ponto exato que precisa de correção.
                </p>
                <br />
            </div>


            <div>
                <p>
                    <strong>4. Bug de projeto</strong>
                    <br / >
                    Uma falha ou erro no código da plataforma que pode causar mal funcionamento. Quando identificamos um 
                    bug, trabalhamos para corrigi-lo o mais rápido possível, para que sua experiência volte ao normal.
                </p>
                <br />
            </div>

            <div>
                <p>
                    <strong>5. Itens de configuração</strong>
                    <br / >
                    São todos os elementos que compõem a infraestrutura que mantém os serviços da Bizpik rodando para você. 
                    Isso inclui a plataforma, servidores, banco de dados, firewalls, soluções de alta disponibilidade e a 
                    rede de dados que garante que tudo funcione perfeitamente.
                </p>
                <br />
            </div>

            <div>
                <p>
                    <strong>6. Produto</strong>
                    <br / >
                    A solução da Bizpik que você contratou. Cada produto é desenhado para atender suas necessidades específicas, 
                    e estamos aqui para garantir que ele sempre funcione da melhor maneira.
                </p>
                <br />
            </div>

            <div>
                <p>
                    <strong>7. Usuário</strong>
                    <br / >
                    Qualquer pessoa que utiliza a solução Bizpik que você contratou, seja um membro da sua equipe ou clientes que 
                    interagem com seus serviços através da nossa plataforma.
                </p>
                <br />
            </div>

            <div>
                <p>
                    <strong>Tempo de inatividade</strong>
                    <br / >
                    Nós entendemos a importância de ter a plataforma Bizpik disponível para você, por isso monitoramos cuidadosamente 
                    o tempo que nossa plataforma fica no ar. Aqui está o que consideramos como Tempo de Inatividade e as exceções que 
                    podem ocorrer.
                </p>
                <br />
            </div>

            <div>
                <p>
                    <strong>O que é o Tempo de inatividade?</strong>
                    <br / >
                    O Tempo de Inatividade refere-se ao total de minutos durante um mês em que a plataforma Bizpik esteve indisponível. 
                    Nosso compromisso é minimizar ao máximo esse tempo, mas existem algumas exceções que não contam como inatividade:
                </p>
                <br />
            </div>

            <div>
                <p>
                    <strong> 1.	Problemas de lentidão ou desempenho parcial</strong>
                    <br / >
                    Se apenas uma parte da plataforma (como o envio de mensagens no Facebook, Instagram ou WhatsApp) estiver lenta, mas 
                    não completamente fora do ar, isso não será contado como inatividade.
                </p>
                <br />
            </div>

            <div>
                <p>
                    <strong> 2.	Aplicativos ou APIs de terceiros</strong>
                    <br / >
                    Qualquer problema causado por aplicativos ou integrações de terceiros, como APIs da Meta, não entra na contagem do 
                    tempo de inatividade. Embora integremos diversas soluções externas, esses elementos estão fora do nosso controle direto.
                </p>
                <br />
            </div>

            <div>
                <p>
                    <strong> 3.	Produtos em versão teste</strong>
                    <br / >
                    Recursos ou produtos classificados como versão teste, também não consta como inatividade, pois está em fase de 
                    desenvolvimento e podem apresentar instabilidade.
                </p>
                <br />
            </div>

            <div>
                <p>
                    <strong> 4.	Fatores externos fora do nosso controle</strong>
                    <br / >
                    Questões como problemas na rede de internet do seu provedor ou falhas em tabelas de roteamento externas não 
                    contam como inatividade, já que não podemos controlá-las diretamente.
                </p>
                <br />
            </div>

            <div>
                <p>
                    <strong> 5.	Manutenção programada</strong>
                    <br / >
                    Eventualmente, precisamos realizar manutenções programadas para melhorar ainda mais nossa plataforma. 
                    Nesses casos, avisamos você com antecedência e esse tempo também não é considerado como inatividade.
                </p>
                <br />
            </div>

            <div>
                <p>
                    <strong>Níveis de serviço</strong>
                    <br / >
                    A Bizpik compromete-se a manter os seguintes níveis de serviço para a sua plataforma, garantindo a 
                    melhor experiência para seus clientes e usuários:
                </p>

                <br />
                <strong>Disponibilidade do serviço</strong>
                <br />
                <br />
                
      <div style={{ overflowX: "auto" }}>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th style={{ border: "1px solid black", padding: "8px" }}>Item</th>
              <th style={{ border: "1px solid black", padding: "8px" }}>Descrição</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ border: "1px solid black", padding: "8px" }}>Tempo de Disponibilidade</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>99,5% do tempo, exceto durante manutenções programadas.</td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black", padding: "8px" }}>Manutenções Programadas</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>Aviso com 48 horas de antecedência. Geralmente entre 02h e 06h (horário de Brasília).</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />

            </div>
            <br />
            <div>
                
                <strong>Resolução de problemas</strong>
                <br />
                <br />
                <div style={{ overflowX: "auto" }}>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th style={{ border: "1px solid black", padding: "8px" }}>Classificação</th>
              <th style={{ border: "1px solid black", padding: "8px" }}>Tempo de Resposta</th>
              <th style={{ border: "1px solid black", padding: "8px" }}>Tempo de Resolução</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ border: "1px solid black", padding: "8px" }}>Crítico</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>1 hora</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>Até 4 horas</td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black", padding: "8px" }}>Alto</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>2 horas</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>Até 8 horas</td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black", padding: "8px" }}>Médio</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>4 horas</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>Até 24 horas</td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black", padding: "8px" }}>Baixo</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>8 horas</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>Até 72 horas</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
                <br />
            </div>

            <div>
                <p>
                    <strong>Penalidades</strong>
                    <br / >
                    Em caso de não cumprimento dos níveis de serviço estabelecidos, a Bizpik 
                    oferecerá uma penalidade de 5% do valor da mensalidade do cliente como 
                    desconto na próxima mensalidade. Esta penalidade se aplica apenas se:
                </p>
                <br />
                <div style={{ overflowX: "auto" }}>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th style={{ border: "1px solid black", padding: "8px" }}>Condição</th>
              <th style={{ border: "1px solid black", padding: "8px" }}>Descrição</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ border: "1px solid black", padding: "8px" }}>Inatividade da Plataforma</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>O tempo de inatividade exceder 1% do total de horas mensais.</td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black", padding: "8px" }}>Tempo de Resposta de Suporte</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>O tempo de resposta para solicitações de suporte não for cumprido conforme descrito acima.</td>
            </tr>
          </tbody>
        </table>
      </div>
                <br />
            </div>

            <div>
                <p>
                    <strong>Atendimento e suporte</strong>
                    <br />
                    <br />
                    <strong>Dúvidas e consultas</strong>
                    <br />
                    •	Suporte via WhatsApp: Oferecemos suporte via WhatsApp, segue nosso 
                    número de contato: (011) 97879-1500. Este canal estará disponível 
                    durante horários comerciais (9h-18h de segunda a sexta).
                    <br />
                    <br />
                    •	E-mail: Para questões mais complexas, os clientes podem 
                    entrar em contato via e-mail: contato@bizpik.com.br. 
                    O tempo de resposta é de até 24 horas.

                </p>
                <br />
            </div>

            <div>
                <p>
                    <strong>Responsabilidades do cliente</strong>
                    <br />
                    Para que possamos atendê-lo da melhor forma possível, pedimos que:
                    <br />
                     •	Mantenha suas informações de contato atualizadas.
                     <br />
                     •	Descreva claramente os problemas ao entrar em contato com o suporte.
                     <br />
                     •	Siga as instruções de nossos agentes durante a resolução de problemas.

                </p>
                <br />
            </div>

            <div>
                <p>
                    <strong>Compromissos da Bizpik</strong>
                    <br / >
                    Na Bizpik, nos comprometemos a garantir que nossos serviços funcionem de 
                    forma eficiente e que você, nosso cliente, tenha o suporte necessário para 
                    aproveitar ao máximo nossa plataforma. Veja abaixo nossos principais compromissos:
                </p>
                <br />
            </div>

            <div>
                <p>
                    <strong>Níveis de serviço para os produtos Bizpik</strong>
                    <br / >
                    Na Bizpik, nosso compromisso é oferecer uma plataforma estável e eficiente. 
                    Abaixo, explicamos os níveis de serviço que mantemos, com base na severidade dos 
                    incidentes, para garantir que você tenha clareza sobre como tratamos cada situação.
                </p>
                <br />
            </div>

            <div>
                <p>
                    <strong>Prazos de recuperação de falhas (RF)</strong>
                    <br / >
                    As falhas sob responsabilidade da Bizpik serão resolvidas dentro dos prazos especificados, 
                    de acordo com a severidade do incidente. Os incidentes são classificados em cinco grupos 
                    de prioridade, baseados na matriz de urgência e impacto:
                    <br />
                    <br />
                       •	Urgência: Define a velocidade necessária para resolver a falha, de acordo com o 
                       impacto percebido no seu negócio.
                       <br />
                       •	Impacto: Refere-se à extensão do dano causado pelo incidente no seu negócio.
                       Observação: A urgência será definida por você, CLIENTE, no momento da abertura do chamado, 
                       enquanto o impacto será avaliado pela equipe Bizpik, que pode reenquadrar a prioridade após 
                       uma análise mais detalhada. Caso ocorra uma reclassificação, a justificativa será fornecida.

                </p>
                <br />
            </div>

            <div>
                <p>
                    <strong>Matriz de prioridade</strong>
                    <br / >
                    <br />
                <div style={{ overflowX: "auto" }}>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th style={{ border: "1px solid black", padding: "8px" }}>Urgência</th>
              <th style={{ border: "1px solid black", padding: "8px" }}>Impacto Alto</th>
              <th style={{ border: "1px solid black", padding: "8px" }}>Impacto Médio</th>
              <th style={{ border: "1px solid black", padding: "8px" }}>Impacto Baixo</th>
              <th style={{ border: "1px solid black", padding: "8px" }}>Impacto Muito Baixo</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ border: "1px solid black", padding: "8px" }}>Alta</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>Crítica</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>Alta</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>Média</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>Baixa Prioridade</td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black", padding: "8px" }}>Média</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>Alta</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>Média</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>Baixa</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>Baixa Prioridade</td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black", padding: "8px" }}>Baixa</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>Média</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>Baixa</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>Baixa Prioridade</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>Baixa Prioridade</td>
            </tr>
          </tbody>
        </table>
      </div>
                <br />
                </p>
                <br />
            </div>

            <div>
                <p>
                    <strong>Critérios de impacto para falhas na plataforma Bizpik</strong>
                    <br / >
                    •	Impacto Alto: Plataforma indisponível para todos os usuários, sem alternativa de uso.
                    <br / >
                    •	Impacto Médio: Plataforma com desempenho muito degradado ou indisponibilidade parcial.
                    <br / >
                    •	Impacto Baixo: Plataforma com baixa perda de performance.
                    <br / >
                    •	Impacto Muito Baixo: Incidentes que não se encaixam nas categorias acima, ou indisponibilidade para um único usuário.

                    <br />
                </p>
                <br />
            </div>

            <div>
                <p>
                    <strong>Prazos para recuperação de incidentes</strong>
                    <br / >
                    Mesmo que seja necessária uma solução paliativa inicial, o prazo de recuperação será sempre dentro dos limites definidos a seguir:
                    <br / >
                    <br />
                <div style={{ overflowX: "auto" }}>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th style={{ border: "1px solid black", padding: "8px" }}>Prioridade</th>
              <th style={{ border: "1px solid black", padding: "8px" }}>SLA</th>
              <th style={{ border: "1px solid black", padding: "8px" }}>Tempo Máximo de Primeira Resposta</th>
              <th style={{ border: "1px solid black", padding: "8px" }}>Tempo Máximo de Recuperação</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ border: "1px solid black", padding: "8px" }}>Crítico</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>1 hora</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>2 horas</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>4 horas</td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black", padding: "8px" }}>Alto</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>2 horas</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>2 horas</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>7 horas</td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black", padding: "8px" }}>Médio</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>4 horas</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>4 horas</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>36 horas</td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black", padding: "8px" }}>Baixo</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>6 horas</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>6 horas</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>96 horas</td>
            </tr>
          </tbody>
        </table>
      </div>
                <br />
                </p>
                <br />
            </div>

            <div>
                <p>
                    <strong>Revisão e atualização</strong>
                    <br / >
                    Este SLA será revisado anualmente e atualizado conforme necessário para atender às necessidades dos nossos clientes e às mudanças no ambiente de negócios.
                    <br />
                </p>
                <br />
            </div>
    </Typography>
      );
    }
    
    export default ServiceLevelAgreement;