/* eslint-disable prettier/prettier */
import AdministrationAndDeclaration from "./AdministrationAndDeclaration";
import Confidentiality from "./Confidentiality";
import Definitions from "./Definitions";
import GeneralProvisions from "./GeneralProvisions";
import IndemnityAndLimitationOfLiability from "./IndemnityAndLimitationOfLiability";
import Initial from "./Initial";
import IntellectualProperty from "./IntellectualProperty";
import MiscellaneousSuppliers from "./MiscellaneousSuppliers";
import PlatformAndResponsibilities from "./PlatformAndResponsibilities";
import PlatformAvailabilityAndMaintenance from "./PlatformAvailabilityAndMaintenance";
import PriceAndPayment from "./PriceAndPayment";
import SpecificsForLoyaltyContracts from "./SpecificsForLoyaltyContracts";
import TermAndTermination from "./TermAndTermination";
import UserRegistration from "./UserRegistration";
import ServiceLevelAgreement from "./ServiceLevelAgreement";

function TermsFragment() {
    return (
        <>
            <Initial />
            {/* 1. Definições */}
            <Definitions />
            {/* 2. Cadastro de Usuários */}
            <UserRegistration />
            {/* 3. Plataforma e Responsabilidades */}
            <PlatformAndResponsibilities />
            {/* 4. Disponibilidade e Manutenção da Plataforma */}
            <PlatformAvailabilityAndMaintenance />
            {/* 5. Propriedade Intelectual */}
            <IntellectualProperty />
            {/* 6. Indenidade e Limitação de Responsabilidade */}
            <IndemnityAndLimitationOfLiability />
            {/* 7. Precos e Pagamento */}
            <PriceAndPayment />
            {/* 8. Vigência e término */}
            <TermAndTermination />
            {/* 9. Fornecedores diversos do BIZPIK */}
            <MiscellaneousSuppliers />
            {/* 10. Especificidades para contratos de Fidelidade */}
            <SpecificsForLoyaltyContracts />
            {/* 11. Administração do Serviço e da Declaração de Responsabilidade */}
            <AdministrationAndDeclaration />
            {/* 12. Confidencialidade */}
            <Confidentiality />
            {/* 13. Disposições Gerais */}
            <GeneralProvisions />
            <div>
                <p>
                    <strong>SEÇÃO 1</strong> – O QUE FAREMOS COM ESTA INFORMAÇÃO?<br /><br />
                    Quando você realiza alguma transação com nossos aplicativos, como parte
                    do processo de compra e venda, coletamos as informações pessoais que
                    você nos dá tais como: nome, e-mail e endereço. Quando você acessa nosso
                    site, também recebemos automaticamente o protocolo de internet do seu
                    computador, endereço de IP, a fim de obter informações que nos ajudam a
                    aprender sobre seu navegador e sistema operacional. Email Marketing será
                    realizado apenas caso você permita. Nestes emails você poderá receber
                    notícia sobre nossas aplicações, novos produtos e outras atualizações.
                </p>
                <br />
                <p>
                    <strong>SEÇÃO 2</strong> – CONSENTIMENTO<br /><br />Como vocês obtêm meu
                    consentimento? Quando você fornece informações pessoais como nome,
                    telefone e endereço, para completar: uma transação, verificar seu cartão
                    de crédito, fazer um pedido, providenciar uma entrega ou retornar uma
                    compra. Após a realização de ações entendemos que você está de acordo
                    com a coleta de dados para serem utilizados pela nossa empresa. Se
                    pedimos por suas informações pessoais por uma razão secundária, como
                    marketing, vamos lhe pedir diretamente por seu consentimento, ou lhe
                    fornecer a oportunidade de dizer não. E caso você queira retirar seu
                    consentimento, como proceder? Se após você nos fornecer seus dados, você
                    mudar de ideia, você pode retirar o seu consentimento para que possamos
                    entrar em contato, para a coleção de dados contínua, uso ou divulgação
                    de suas informações, a qualquer momento, entrando em contato conosco em
                    comercial@tecware.com.br ou nos enviando uma correspondência em: Tecware
                    Consultoria de Software – Rua Cardoso de Almeida, 634 - Conj. 11 – CEP
                    05013-000 - Perdizes – São Paulo – SP – Brasil
                </p>
                <br />
                <p>
                    <strong>SEÇÃO 3</strong> – DIVULGAÇÃO<br /><br />Podemos divulgar suas
                    informações pessoais caso sejamos obrigados pela lei para fazê-lo ou se
                    você violar nossos Termos de Serviço.
                </p>
                <br />
                <p>
                    <strong>SEÇÃO 4</strong> – SERVIÇOS DE TERCEIROS<br /><br />No geral, os
                    fornecedores terceirizados usados por nós irão apenas coletar, usar e
                    divulgar suas informações na medida do necessário para permitir que eles
                    realizem os serviços que eles nos fornecem. Entretanto, certos
                    fornecedores de serviços terceirizados, tais como gateways de pagamento
                    e outros processadores de transação de pagamento, têm suas próprias
                    políticas de privacidade com respeito à informação que somos obrigados a
                    fornecer para eles de suas transações relacionadas com compras. Para
                    esses fornecedores, recomendamos que você leia suas políticas de
                    privacidade para que você possa entender a maneira na qual suas
                    informações pessoais serão usadas por esses fornecedores. Em particular,
                    lembre-se que certos fornecedores podem ser localizados em ou possuir
                    instalações que são localizadas em jurisdições diferentes que você ou
                    nós. Assim, se você quer continuar com uma transação que envolve os
                    serviços de um fornecedor de serviço terceirizado, então suas
                    informações podem tornar-se sujeitas às leis da(s) jurisdição(ões) nas
                    quais o fornecedor de serviço ou suas instalações estão localizados.
                    Como um exemplo, se você está localizado no Canadá e sua transação é
                    processada por um gateway de pagamento localizado nos Estados Unidos,
                    então suas informações pessoais usadas para completar aquela transação
                    podem estar sujeitas a divulgação sob a legislação dos Estados Unidos,
                    incluindo o Ato Patriota. Uma vez que você deixe o site da nossa loja ou
                    seja redirecionado para um aplicativo ou site de terceiros, você não
                    será mais regido por essa Política de Privacidade ou pelos Termos de
                    Serviço do nosso site. Links: Quando você clica em links na nossa loja,
                    eles podem lhe direcionar para fora do nosso site. Não somos
                    responsáveis pelas práticas de privacidade de outros sites e lhe
                    incentivamos a ler as declarações de privacidade deles.
                </p>
                <br />
                <p>
                    <strong>SEÇÃO 5</strong> – SEGURANÇA<br /><br />Para proteger suas
                    informações pessoais, tomamos precauções razoáveis e seguimos as
                    melhores práticas da indústria para nos certificar que elas não serão
                    perdidas inadequadamente, usurpadas, acessadas, divulgadas, alteradas ou
                    destruídas. Se você nos fornecer as suas informações de cartão de
                    crédito, essa informação é criptografada usando tecnologia “secure
                    socket layer” (SSL) e armazenada com uma criptografia AES-256. Embora
                    nenhum método de transmissão pela Internet ou armazenamento eletrônico é
                    100% seguro, nós seguimos todos os requisitos da PCI-DSS e implementamos
                    padrões adicionais geralmente aceitos pela indústria.
                </p>
                <br />
                <p>
                    <strong>SEÇÃO 6</strong> – ALTERAÇÕES PARA ESSA POLÍTICA DE
                    PRIVACIDADE<br /><br />Reservamos o direito de modificar essa política
                    de privacidade a qualquer momento, então por favor, revise-a com
                    frequência. Alterações e esclarecimentos vão surtir efeito imediatamente
                    após sua publicação no site. Se fizermos alterações de materiais para
                    essa política, iremos notificá-lo aqui que eles foram atualizados, para
                    que você tenha ciência sobre quais informações coletamos, como as
                    usamos, e sob que circunstâncias, se alguma, usamos e/ou divulgamos
                    elas. Se nossa loja for adquirida ou fundida com outra empresa, suas
                    informações podem ser transferidas para os novos proprietários para que
                    possamos continuar a vender produtos para você.
                </p>
            </div>
            <ServiceLevelAgreement/>
            {/* 14. Acordo de Nível de Serviço (SLA) */}
        </>
    )
}

export default TermsFragment;