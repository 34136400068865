import React from "react";
import { Box } from "@mui/material";

import NavBar from "./components/NavBar";
import PageContent from "./components/PageContent";
import Footer from "./components/Footer";
import { INavigationLink } from "../../types/nav";
import WhatsAppButton from "./components/WhatsAppButton";

interface NavigationTemplateProps extends React.PropsWithChildren {
  links: INavigationLink[];
  buttonNav?: string; // Novo campo para o link do botão header
  buttonFooter?: string; // Novo campo para o link do botão footer
}

export default function NavigationTemplate({
  links,
  buttonNav,
  children,
  buttonFooter
}: NavigationTemplateProps) {
  return (
    <Box component="main" className="min-h-screen">
      <NavBar links={links} buttonNav={buttonNav} />
      <PageContent component="article" children={children} />
      <Footer links={links} buttonFooter={buttonFooter} />
      <WhatsAppButton />
    </Box>
  );
}
